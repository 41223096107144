import React from "react";
import GetColors from "../../components/styles/colors"


function ParseCoinDataResponse (res, isMobile){
    if (res.coinData === null) {
        return {
            prices: [0],
            dates: ['data is null'],
            label: 'data is null',
            lastPrice: "NULL",
            hidden: true,
            pointColor: "rgba(0,0,0,0.34)",
            lineColor: "rgba(0,0,0,0.34)"
        }
    }
    var coinData = {};
    var priceArr = [];
    var dateArr = [];

    var coinDataRes = res.coinData;
    if (isMobile) {
        coinDataRes = res.coinData.slice(-18);
    }

    for (var i = 0; i < coinDataRes.length; i++) {
        priceArr = priceArr.concat([JSON.parse(coinDataRes[i].CurrencyData.String).Price]);
        dateArr = dateArr.concat([coinDataRes[i].CreatedAt.replace( /\..*/g, "" )]);
    }

    coinData.prices = priceArr;
    coinData.dates = dateArr;
    var tmpJsonData = JSON.parse(coinDataRes[0].CurrencyData.String);
    var labelCoinId = tmpJsonData.CoinId
    if (tmpJsonData.CoinId === "TONCOIN") {
        labelCoinId = "TON"
    }
    coinData.label = labelCoinId + " / " + tmpJsonData.CurrencyId;
    coinData.lastPrice = Math.round(priceArr[coinDataRes.length - 1] * 100) / 100;

    coinData.hidden = true;
    if (tmpJsonData.CoinId === "TONCOIN") {
        coinData.hidden = false;
    }

    coinData.pointColor = GetColors().get(tmpJsonData.CoinId);
    coinData.lineColor = GetColors("line").get(tmpJsonData.CoinId);

    return coinData;
}

export default ParseCoinDataResponse;