import React from "react";
import ParseCoinDataResponse from "../processor/parseResponse"
import GetData from "../api/GetData";
import GetParameters from "../config/config";

const config = GetParameters()

const url = config.cryptocheckerUrl + "/getData?coinName=";

async function GetDataByCryptochecker(coinName, isMobile) {
    var fullUrl = url + coinName;
    const response = await GetData(fullUrl, config.cryprtocheckerHeaders).catch(function (error) {
        console.log("response isn't successful", error);
        return {}
    });

    if (response.status !== 200) {
        return {
            prices: [0],
            dates: ['error with get data'],
            label: 'error with get data',
            lastPrice: "NULL",
            hidden: true,
            pointColor: "rgba(0,0,0,0.34)",
            lineColor: "rgba(0,0,0,0.34)"
        }
    }

    //TODO add api request for mobile (backend needed)
    return ParseCoinDataResponse(response.data, isMobile);

}


export default GetDataByCryptochecker;