import React from "react";

const cryptocheckerUrl = "https://api-cryptochecker.it-q.ru";

const cryprtocheckerHeaders = {
    'Content-Type': 'application/json',
    'apiKey': 'yahv9go7Aichue0yeuf4',
};

function GetParameters(type) {
    if (type === "url") {
        return cryptocheckerUrl
    }

    if (type === "headers") {
        return cryprtocheckerHeaders
    }

    return {
        cryptocheckerUrl: cryptocheckerUrl,
        cryprtocheckerHeaders: cryprtocheckerHeaders,
    }
}

export default GetParameters;