import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Header, Footer, Main} from "./components/page";
import {useMediaQuery} from 'react-responsive'

// TODO button here
let sign = <div>Join to automatic <a style={{color: "#51BEF9"}} href="https://t.me/coinreactions"><b>CoinReactions</b><img className={"iconFooter"} src="telegram.png"/></a></div>;
let headerText = "Coin dashboard";


function App() {
    const isMobileDevice = useMediaQuery({
        query: "(min-device-width: 300px)",
    });

    const isDesktop = useMediaQuery({
        query: "(min-device-width: 1024px)",
    });

    return (
        <div className="App">
            <Router>
                <Header headerText={headerText} isMobile={isMobileDevice && !isDesktop}/>
                <Routes>
                    <Route path='/' element={<Main isMobile={isMobileDevice && !isDesktop}/>}/>
                </Routes>
                <Footer footerChild={sign}/>
            </Router>
        </div>
    );
}

export default App;
