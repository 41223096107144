import React, { Component } from 'react';
import GetDataByCryptochecker from "../../core/services/cryptochecker";
import "./main.css"
import LineChart from "../charts/linechart";


class Main extends React.Component {
    constructor() {
        super();
        this.state = { dataTon: [], dataBtc: [], dataEth: [], dataUsd: [] };
    }

    async componentDidMount() {
        const pointsDataTon = await GetDataByCryptochecker("TONCOIN", this.props.isMobile);
        const pointsDataBtc = await GetDataByCryptochecker("BTC", this.props.isMobile);
        const pointsDataEth = await GetDataByCryptochecker("ETH", this.props.isMobile);
        const pointsDataUsd = await GetDataByCryptochecker("USD", this.props.isMobile);
        this.setState({ dataTon: pointsDataTon });
        this.setState({ dataBtc: pointsDataBtc });
        this.setState({ dataEth: pointsDataEth });
        this.setState({ dataUsd: pointsDataUsd });
    }

    buildLineHeader() {
        if (this.props.isMobile) {
            return <h1 className={this.buildSecondHeaderClass()}>Charts by last day</h1>;
        } else {
            return <h1 className={this.buildHeaderClass()}>Charts by last week</h1>;
        }
    }

    buildLineChart() {
        if (this.props.isMobile) {
            return <LineChart height={"375px"} coinsData={[this.state.dataTon, this.state.dataBtc, this.state.dataEth, this.state.dataUsd]}/>;
        } else {
            return <LineChart height={"450px"} coinsData={[this.state.dataTon, this.state.dataBtc, this.state.dataEth, this.state.dataUsd]}/>;
        }
    }

    buildSummaryClassName() {
        if (this.props.isMobile) {
            return "summaryMobile";
        } else {
            return "summary";
        }
    }

    buildHeaderClass() {
        if (this.props.isMobile) {
            return "headerMobile";
        } else {
            return "header";
        }
    }

    buildSecondHeaderClass() {
        if (this.props.isMobile) {
            return "secondHeaderMobile";
        } else {
            return "secindHeader";
        }
    }



    render() {
        return (
            <div className={"main"}>
                <h1 className={this.buildHeaderClass()}>Last prices</h1>
                <div className={this.buildSummaryClassName()}>
                    <div>TON/USD: <div className={"price"} style={{color: this.state.dataTon.lineColor}}><b>{this.state.dataTon.lastPrice}</b></div></div>
                    <div>BTC/USD: <div className={"price"} style={{color: this.state.dataBtc.lineColor}}><b>{this.state.dataBtc.lastPrice}</b></div></div>
                    <div>ETH/USD: <div className={"price"} style={{color: this.state.dataEth.lineColor}}><b>{this.state.dataEth.lastPrice}</b></div></div>
                    <div>USD/RUB: <div className={"price"} style={{color: this.state.dataUsd.lineColor}}><b>{this.state.dataUsd.lastPrice}</b></div></div>
                </div>
                {this.buildLineHeader()}
                {this.buildLineChart()}
            </div>
        );
    }
}


export default Main;
