import React from "react";
import './footer.css'


function Footer({ footerChild }) {
    return (
        <div>
            <div className="phantom" />
            <div className="Footer">
                { footerChild }
            </div>
        </div>
    )
}

export default Footer
