import React from 'react'
import './header.css';

class Header extends React.Component {

    buildHeaderClassName() {
        if (this.props.isMobile) {
            return "HeaderMobile";
        } else {
            return "Header";
        }
    }

    buildHeaderSubClassName() {
        if (this.props.isMobile) {
            return "Header-SubtitleMobile";
        } else {
            return "Header-Subtitle";
        }
    }

    refreshPage() {
        window.location.reload(false);
    }

    render() {
        return (
            <div className={this.buildHeaderClassName()}>
                <h1 className={this.buildHeaderSubClassName()}>{this.props.headerText}</h1>
                <div className={"buttons"}>
                    <button onClick={this.refreshPage} className={"btn-grey"}><img className={"icon"} src="reload.png"/></button>
                </div>
            </div>
        )
    }
}

export default Header
