import React, { Component } from 'react';
import {getDatasetAtEvent, Line} from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }  from 'react-chartjs-2';


class LineChart extends React.Component {

    buildDataset(coinsData) {
        var datasetsTmp = []
        for (var i = 0; i < coinsData.length; i++) {
            datasetsTmp = datasetsTmp.concat({
                label: coinsData[i].label,
                fill: false,
                lineTension: 0.5,
                backgroundColor: coinsData[i].pointColor,
                borderColor: coinsData[i].lineColor,
                borderWidth: 2,
                data: coinsData[i].prices,
                hidden: coinsData[i].hidden,
            })
        }
        return {
            labels: coinsData[0].dates,
            datasets: datasetsTmp
        }
    }

    buildOptions() {
        return {
            title:{
                display: true,
                // text: this.props.label,
                // fontSize:20
            },
            legend:{
                display: true,
                position:'right'
            },
            scales: {
                x: {
                    display: false, //this will remove all the x-axis grid lines
                    ticks: {
                        display: false //this will remove only the label
                    }
                }
            },
            maintainAspectRatio: false,
        }
    }

    render() {
        return (
            <div>
                <Line
                    data={this.buildDataset(this.props.coinsData)}
                    height={this.props.height}
                    // width={this.props.width}
                    options={this.buildOptions()}
                />
            </div>
        );
    }
}


export default LineChart;