import React from "react";

const PointColors = new Map([
    ['TONCOIN', "#009ced"],
    ['BTC', "#f6931a"],
    ['ETH', "#464a75"],
    ['USD', "#85bb65"]
]);
const LineColors = new Map([
    ['TONCOIN', "#91D5F9"],
    ['BTC', "#FCD29E"],
    ['ETH', "#ACB0CF"],
    ['USD', "#CBE7BB"]
]);
const MiddleColors = new Map([
    ['TONCOIN', "#51BEF9"],
    ['BTC', "#FCB864"],
    ['ETH', "#979CCF"],
    ['USD', "#BAE79F"]
]);

function GetColors (type) {
    if (type === "line") {
        return LineColors
    }
    if (type === "middle") {
        return MiddleColors
    }

    return PointColors
}

export default GetColors;